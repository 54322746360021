const isReportPage = window.location.pathname.includes("/admin/reports/new");

if (isReportPage) {
  $(window).on("load", function () {
    const report_type = $("#report_report_type")[0]?.value;
    const intialUrl = buildUrl(report_type);
    getAndRefreshFields(intialUrl);

    $("#report_report_type").on("change", (event) => {
      const url = buildUrl(event.target.value);
      getAndRefreshFields(url);
    });
  });
}

const buildUrl = (report_type) => {
  return `fields?report_type=${report_type}`;
};

const getAndRefreshFields = (url) => {
  $.getJSON(url, (result) => {
    cleanDynamicFields();

    result.forEach((item) => {
      addDynamicField(item);
    });
  });
};

const cleanDynamicFields = () => {
  document
    .querySelectorAll("fieldset.inputs ol li.dynamic")
    .forEach((field) => {
      $(field).remove();
    });
};

const addDynamicField = (item) => {
  const itemInputName = item.param_name;
  const itemInputType = item.param_type;
  const itemLabelText = item.param_label;

  const label = $("<label>", {
    for: `report_${itemInputName}`,
    class: "label",
  }).append(itemLabelText);
  const input = $("<input>", {
    type: itemInputType,
    name: `report[extra_params][${itemInputName}]`,
    id: `report_${itemInputName}`,
  });

  $("fieldset.inputs ol").append(
    $("<li>", {
      class: `input ${itemInputType} optional dynamic`,
    }).append(label, input)
  );
};
